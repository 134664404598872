
.App {
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
  padding: 50px;
  background-color: black;
}


.grid-container {
  display: grid;
  grid-template-columns: 220px 1fr 220px;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  grid-template-areas:
    "tl background tr"
    "bl background br"
}
.tl { grid-area: tl; }
.tr { grid-area: tr; }
.bl { grid-area: bl; align-self: flex-end; }
.br { grid-area: br; align-self: flex-end; }
.background { grid-area: background; }


.background {
  position: relative;
  --tl: blue;
  --tr: white;
  --bl: orange;
  --br: black;
  border: 1px solid white;
  position: relative;
  background: linear-gradient(90deg, var(--tl), var(--tr));
}

.code {
  position: absolute;
  bottom: 20px;
  right: 20px;
  white-space: pre;
  font-family: 'Courier New', Courier, monospace;
  border: 1px solid #ffffff;
  padding: 20px;
}

.background:hover .code {
  background-color: rgba(255,255,255,0.75);
}

.background:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  mask-image: linear-gradient(to bottom, transparent, black);
  background: linear-gradient(90deg, var(--bl), var(--br));	
}
